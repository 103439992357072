import React, { useState } from "react";
import useGameStore from "../../lib/store/GameStore";
import { Combobox } from "@headlessui/react";
import Dialog from "@mui/material/Dialog";
import { ModalCloseButton } from "../../../global/components/BaseDialog";
import { IconMagnifyingGlass } from "../../../global/components/icons/IconMagnifyingGlass";
import { postSubmission } from "../../lib/utils/submit";
import {
  getFixtureByTeamId,
  getOpponentFromFixture,
  isGameStarted,
} from "../../lib/utils/sport";
import { IconInjury } from "../../../global/components/icons/IconInjury";

function SearchDialog({ overallPick, isOpen, closeModal, selectedPlayer }) {
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={closeModal}
        transitionDuration={300}
        componentsProps={{
          backdrop: { style: { backgroundColor: "rgba(0, 0, 0, 0.25)" } },
        }}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="m-4 sm:m-6 md:m-20"
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            margin: "0px",
            display: "block",
          },
        }}
        PaperProps={{
          sx: { margin: "0 auto", borderRadius: "0.75rem", maxWidth: "36rem" },
        }}
      >
        <ModalBody overallPick={overallPick} closeModal={closeModal} />{" "}
      </Dialog>
    </>
  );
}

function OpponentString({ player }) {
  const [fixtures] = useGameStore((state) => [state.fixtures]);

  const fixture = getFixtureByTeamId(fixtures, player.team.id);
  const opponent = getOpponentFromFixture(fixture, player.team.id);
  function formatDateInLocalTimezone(dateString) {
    const date = new Date(dateString);
    const options = {
      weekday: "short",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = date.toLocaleString("en-AU", options);

    // Remove the space between minutes and "pm"
    return formattedDate.replace(/(\d+):(\d+)\s([ap]m)/i, "$1:$2$3");
  }

  return (
    <>
      <span className="font-semibold">
        {opponent.isHome ? "@" : "vs"} {opponent.team.abbr}
      </span>
      , {formatDateInLocalTimezone(fixture.startAt)}
    </>
  );
}

function ModalBody({ overallPick, closeModal }) {
  const [
    players,
    contest,
    avgPoints,
    setIsShareOpen,
    setShareGroup,
    setShareJpegData,
    currentRound,
    fixtures,
  ] = useGameStore((state) => [
    state.players,
    state.contest,
    state.avgPoints,
    state.setIsShareOpen,
    state.setShareGroup,
    state.setShareJpegData,
    state.currentRound,
    state.fixtures,
  ]);
  const [picks, persistPick] = useGameStore((state) => [
    state.picks,
    state.persistPick,
  ]);

  const isFinalPick = overallPick % 5 === 0;

  const takenIds = Object.values(picks);
  const selectedPlayerId = picks[overallPick];

  const [hideSelected, setHideSelected] = useState(true);
  function toggleSelected() {
    setHideSelected(!hideSelected);
  }

  const [query, setQuery] = useState("");
  const filteredHidden = hideSelected
    ? players.filter((player) => {
        return player.id === selectedPlayerId || !takenIds.includes(player.id);
      })
    : players;
  const filteredPeople =
    query.length < 1
      ? filteredHidden.slice(0, 40)
      : players
          .filter((player) => {
            return (
              player.name.toLowerCase().includes(query.toLowerCase()) &&
              !takenIds.includes(player.id)
            );
          })
          .slice(0, 20);

  function selectPlayer(player) {
    if (isGameStarted(player)) {
      return;
    }
    // if (guessesLeft === MAX_GUESSES) {
    //   trackLevelStart({
    //     gameName: analyticsGameName,
    //     levelNumber: gridLevelNumber,
    //   });
    // }
    persistPick(overallPick, player);
    closeModal();

    const updatedPicks = Object.assign({}, picks);
    updatedPicks[overallPick] = player?.id;
    console.log(updatedPicks);

    if (updatedPicks.hasOwnProperty(5)) {
      setShareGroup(Math.round(overallPick / 5));
      setShareJpegData(null);

      setTimeout(async () => {
        const result = await postSubmission(updatedPicks);
        console.log("server says", result);
        if (result.status === "error") {
          alert(result.message);
          window.location.reload();
        } else {
          setIsShareOpen(true);
        }
      }, 10);
    }

    // if (guessesLeft <= 1) {
    //   closeModal();
    //   setIsResultOpen(true);
    // }
  }

  return (
    <div className="">
      <div
        id="scroll-dialog-title"
        className="sticky top-0 z-50 flex items-center justify-start border-b border-b-gray-200 bg-white p-4 text-center text-lg font-semibold leading-6 text-sky-900"
      >
        <ModalCloseButton clickCallback={closeModal} />

        <span className="mr-2 inline-flex items-center justify-center">
          Select a player
        </span>
      </div>
      <Combobox onChange={selectPlayer}>
        <div className="relative">
          {IconMagnifyingGlass}
          <Combobox.Input
            onChange={(event) => setQuery(event.target.value)}
            // displayValue={(person) => person.name}
            className="h-12 w-full border-0 bg-transparent pl-11 pr-9 text-gray-900 placeholder:text-gray-400 focus:outline-none focus:ring-0 dark:bg-gray-700 dark:text-white dark:placeholder:text-gray-200 sm:text-md"
            placeholder={`Search...`}
            autoComplete="off"
            autoCorrect="off"
          />
        </div>

        {/*<div className="sticky top-[3.5rem] z-40 border-y border-gray-200 bg-white backdrop-blur-md dark:border-gray-700 dark:bg-gray-800/70">*/}
        {/*  <div className="mx-auto overflow-auto whitespace-nowrap scrollbar-none">*/}
        {/*    <ul className="mt-0 flex items-center space-x-2 px-2 py-2 text-xs font-medium">*/}
        {/*      <li className="inline-flex items-center">*/}
        {/*        <ToggleChip selected={!hideSelected} onClick={toggleSelected}>*/}
        {/*          Most Popular*/}
        {/*        </ToggleChip>*/}
        {/*      </li>*/}
        {/*      <li className="inline-flex">*/}
        {/*        <button className="rounded-full border border-gray-500 px-3 py-1.5 text-gray-700 transition-colors hover:bg-gray-200 hover:text-black dark:text-gray-400 dark:hover:bg-primary-800 dark:hover:text-white">*/}
        {/*          MID*/}
        {/*        </button>*/}
        {/*      </li>*/}
        {/*    </ul>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {filteredPeople.length > 0 && (
          <Combobox.Options
            static={true}
            className={`z-20 scroll-py-2 overflow-y-auto border-0 border-t border-gray-200 py-2 text-md text-gray-800 dark:bg-gray-700`}
          >
            {filteredPeople.map((player) => (
              /* Use the `active` state to conditionally style the active option. */
              /* Use the `selected` state to conditionally style the selected option. */

              <Combobox.Option
                disabled={isGameStarted(player)}
                className={isGameStarted(player) ? "opacity-50" : ""}
                key={player.id}
                value={player}
              >
                {({ active, selected }) => (
                  <div
                    className={`flex cursor-default select-none items-center justify-between px-4 py-2 font-medium leading-snug ${
                      active ? "bg-gray-100" : ""
                    }`}
                    id="headlessui-combobox-option-5"
                    role="option"
                    tabIndex="-1"
                    aria-selected="false"
                    data-headlessui-state=""
                  >
                    <div className="my-1 flex items-center justify-center dark:text-white">
                      <div className="mr-3 h-7 w-7">
                        <img
                          className="h-7 w-7 rounded-full"
                          src={`/build/images/sport/teams/logos/512/${player.team.id}.png`}
                        />
                      </div>
                      <div className="flex-grow text-md">
                        <span className="inline-flex whitespace-nowrap items-center justify-center">
                          {player.firstName} {player.lastName}{" "}
                          <span className="ml-1 text-slate-700 font-semibold leading-none text-xxs rounded-md bg-slate-200 mt-0.5 px-1 py-1">
                            {parseFloat(avgPoints[player.id] || 0).toFixed(1)}
                          </span>
                        </span>
                        <div className="-mt-0.5 text-xs font-normal leading-snug text-sky-950 dark:text-gray-400">
                          <OpponentString player={player} />
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col items-end justify-center sm:pr-3">
                      {contest.stats.totalCount > 0 && (
                        <div className="text-md font-normal text-right text-sky-950">
                          {(
                            ((contest.stats.selections[player.id] || 0) /
                              contest.stats.totalCount) *
                            100
                          ).toFixed(1)}
                          % selected
                        </div>
                      )}
                      {player.injuryStatus > 0 && (
                        <span className="pl-1 pr-2 py-px bg-red-500 text-xxxs font-light text-white rounded-md inline-flex items-center justify-center">
                          <IconInjury className="h-2.5 w-2.5 mr-1" /> Injured
                        </span>
                      )}
                    </div>
                  </div>

                  // <SearchRow
                  //   player={player}
                  //   overallPick={overallPick}
                  //   closeModal={closeModal}
                  // />
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </Combobox>
    </div>
  );
}

export default SearchDialog;
