import React from "react";
import useGameStore from "../lib/store/GameStore";
import { BoardRow } from "./BoardRow";
import { ResetButton } from "./buttons/ResetButton";
import { ShareButton } from "./buttons/ShareButton";
import { getPlayerById } from "../lib/utils/helpers";
import { IconChevronLeft } from "../../global/components/icons/IconChevronLeft";
import { IconChevronRight } from "../../global/components/icons/IconChevronRight";

function ordinal(number) {
  if (number > 3 && number < 21) return number + "th";
  switch (number % 10) {
    case 1:
      return number + "st";
    case 2:
      return number + "nd";
    case 3:
      return number + "rd";
    default:
      return number + "th";
  }
}

export default function () {
  const [lockState, round, currentRound, roundPoints, picks, submission, init] =
    useGameStore((state) => [
      state.lockState,
      state.round,
      state.currentRound,
      state.scores,
      state.picks,
      state.submission,
      state.init,
    ]);

  let total = 0;
  for (let pickNum = 1; pickNum <= 5; pickNum++) {
    const multiplier = 6 - pickNum;
    const playerId = picks[pickNum];

    const selectedPlayer = getPlayerById(playerId);

    const points = roundPoints && roundPoints[selectedPlayer?.id];
    if (points) {
      total += points * multiplier;
    }
  }

  return (
    init && (
      <>
        <div className="mx-auto mb-6 max-w-md rounded-md bg-slate-200 p-2 md:max-w-screen-md md:bg-transparent md:p-0">
          <div
            id="leaderboard-inner"
            className="mx-auto w-full max-w-md space-y-2 rounded-md bg-slate-200 md:p-2"
          >
            <div className="flex items-center justify-between text-sky-950">
              <a
                href={`round-${Math.max(1, round?.number - 1)}`}
                className={`${
                  round?.number <= 1 &&
                  "pointer-events-none cursor-not-allowed opacity-50"
                } -my-1 flex items-center justify-center font-saira uppercase text-sky-950`}
              >
                <IconChevronLeft
                  className="h-7 w-7 dark:text-white"
                  aria-hidden="true"
                />
              </a>
              {round && (
                // <div className="flex items-center justify-center font-saira uppercase text-sky-950">
                //   <IconLock className="mr-1" /> {round.name} Picks Locked
                // </div>
                <div className="flex items-center justify-center font-saira uppercase">
                  {round.number === currentRound.number &&
                  lockState === "LOCKED" ? (
                    <>
                      <IconLock className="mr-1" /> {round.name}{" "}
                      <span className="ml-1 inline-flex items-center rounded-lg bg-green-300 px-1.5 py-1 text-xs font-medium leading-none text-green-900 dark:bg-green-900 dark:text-green-300">
                        LIVE
                      </span>
                    </>
                  ) : (
                    <>
                      {round.name}{" "}
                      {lockState === "LOCKED" ? "Results" : "Picks"}
                    </>
                  )}
                </div>
              )}
              <a
                href={`round-${Math.min(24, round?.number + 1)}`}
                className={`${
                  round?.number + 1 > 24 &&
                  "pointer-events-none cursor-not-allowed opacity-50"
                } -my-1 flex items-center justify-center font-saira uppercase text-sky-950`}
              >
                <IconChevronRight
                  className="h-7 w-7 dark:text-white"
                  aria-hidden="true"
                />
              </a>
            </div>
            <PickRows start={1} num={5} lockState={lockState} />
            {lockState !== "PRE" &&
              round &&
              (round.number < currentRound.number || total > 0) && (
                <div className="flex flex-col items-center justify-between py-1  text-sky-950">
                  <span className="font-saira text-4xl font-bold uppercase">
                    {total} Pts
                  </span>
                  {submission && (
                    <span className="font-redhat text-xs font-medium normal-case">
                      {round.number === currentRound.number && "Currently"}{" "}
                      {ordinal(submission.totalRank)} overall
                    </span>
                  )}
                </div>
              )}
          </div>
        </div>
        <div className="mb-8 flex items-center justify-center space-x-4 text-center">
          {lockState === "PRE" ? (
            <>
              <ResetButton />
              <ShareButton group={1} />
            </>
          ) : (
            <ShareButton group={1} />
          )}
        </div>
      </>
    )
  );
}

function IconLock(props) {
  return (
    <svg
      viewBox="0 0 1024 1024"
      fill="currentColor"
      height="1em"
      width="1em"
      {...props}
    >
      <path d="M832 464h-68V240c0-70.7-57.3-128-128-128H388c-70.7 0-128 57.3-128 128v224h-68c-17.7 0-32 14.3-32 32v384c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V496c0-17.7-14.3-32-32-32zM540 701v53c0 4.4-3.6 8-8 8h-40c-4.4 0-8-3.6-8-8v-53a48.01 48.01 0 1156 0zm152-237H332V240c0-30.9 25.1-56 56-56h248c30.9 0 56 25.1 56 56v224z" />
    </svg>
  );
}

export function PickRows({ order, start, num, lockState }) {
  const picks = [];
  for (let pickNum = start; pickNum < start + num; pickNum++) {
    picks.push(
      <BoardRow key={pickNum} overallPick={pickNum} lockState={lockState} />,
    );
  }

  return picks;
}
