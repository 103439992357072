import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "ilSlots",
    "ilSlotOptions",
    "rookieSlots",
    "rookieSlotOptions",
    "vetSlots",
    "vetSlotOptions",
  ];

  connect() {
    this.toggleIlSlotOptions();
    this.toggleRookieSlotOptions();
    this.toggleVetSlotOptions();

    this.observeChanges(
      this.ilSlotsTarget,
      this.toggleIlSlotOptions.bind(this),
    );
    this.observeChanges(
      this.rookieSlotsTarget,
      this.toggleRookieSlotOptions.bind(this),
    );
    this.observeChanges(
      this.vetSlotsTarget,
      this.toggleVetSlotOptions.bind(this),
    );
  }

  observeChanges(target, callback) {
    target.addEventListener("input", callback);
    target.addEventListener("change", callback);
  }

  toggleIlSlotOptions() {
    const ilSlotsValue = parseInt(this.ilSlotsTarget.value, 10);
    this.ilSlotOptionsTarget.style.display =
      ilSlotsValue > 0 ? "block" : "none";
  }

  toggleRookieSlotOptions() {
    const rookieSlotsValue = parseInt(this.rookieSlotsTarget.value, 10);
    this.rookieSlotOptionsTarget.style.display =
      rookieSlotsValue > 0 ? "block" : "none";
  }

  toggleVetSlotOptions() {
    const vetSlotsValue = parseInt(this.vetSlotsTarget.value, 10);
    this.vetSlotOptionsTarget.style.display =
      vetSlotsValue > 0 ? "block" : "none";
  }
}
